import React, {useState, useRef, useEffect} from 'react';
import { Navigate } from "react-router-dom";
import {useAuth} from '../contexts/AuthContext.js'
import { doc, getDoc, collection, query, where, orderBy, getDocs, addDoc, serverTimestamp, setDoc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase.js'
import Entry from './Entry.jsx'
import AddIcon from "./icons/AddIcon.jsx";
import GarbageIcon from "./icons/GarbageIcon.jsx";
import ForwardIcon from "./icons/ForwardIcon.jsx";
import BackIcon from "./icons/BackIcon.jsx";


//This JournalEdit page displays what a logged in user sees when then are trying to edit their journal.
//For the page that non-users can browse, check JournalView.jsx.

export default function JournalEdit(){
    const primaryUrl = useRef('');
    const {currentUser} = useAuth();
    const [error, setError] = useState('');
    const [boardPicture, setBoardPicture] = useState('')

    const [userProfile, setUserProfile] = useState([]);
    useEffect(()=> {
        const userProfileRef = doc(db, "users", currentUser.uid);
        const getUserProfile = async () => {
            try {
                const data = await getDoc(userProfileRef);
                setUserProfile(data.data());
                primaryUrl.current = data.data().primaryURL;
                setBoardPicture(data.data().primaryURL)
            } catch(err){
                console.error(err);
            }
        };
        getUserProfile();
    }, [currentUser.uid]);

    const [allUpdates, setAllUpdates] = useState([]);
    const updateList = collection(db, "updates");
    const [newUpdateTracker, setNewUpdateTracker] = useState(0);
    const [updateNumber, setUpdateNumber] = useState(0);

    //This is passed to Entry-->Modal-->imageCropper to force a refresh of the Updates when an image is updated.
    function newUpdateIncrementer({url}){
        // This function does absolutely nothing, but somehow it solves the problem where clicking the
        // back button and then forward button still has the old picture displayed after a photo update.
        const newUpdateList = allUpdates;
        newUpdateList[updateNumber].photoURL = url;
    }

    useEffect(() => {
        let updates = [];
        
        const queryUpdates = query(
            updateList,
            where("user", "==", currentUser.uid),
            orderBy("createdAt", "desc"),
        );
        const fetchUpdates = async () => {
            let data = await getDocs(queryUpdates);
            data.forEach((doc) => {
                updates.push({ID: doc.id, ...doc.data() });
            })
            setAllUpdates(updates)
        };
        fetchUpdates();
        setUpdateNumber(0);
    },[newUpdateTracker]);
    
    const createNew = async (e) =>  {
        e.preventDefault();
        await addDoc(updateList, {
            createdAt: serverTimestamp(),
            user: currentUser.uid,
            photoURL: "https://firebasestorage.googleapis.com/v0/b/dev-env-240ae.appspot.com/o/default_primary.webp?alt=media&token=49a1e53d-d932-48c6-bba7-8493ab5f1798",
            primaryDesc: '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Tell us about your plants...","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"textFormat":0}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}'
        });
        setNewUpdateTracker(newUpdateTracker + 1);
        try{
            await setDoc(doc(db, 'users', currentUser.uid), {recentUpdate: serverTimestamp()}, {merge:true})
          } catch(err){
            console.error(err);
          }
    };

    function moveBackward() {
        if (updateNumber === 0) {
            setUpdateNumber(allUpdates.length-1)
        } else {
            setUpdateNumber(updateNumber - 1)
        }
    }

    function moveForward() {
        if (updateNumber === allUpdates.length-1) {
            setUpdateNumber(0);
        } else {
            setUpdateNumber(updateNumber + 1)
        }
    }

    const deleteRef = useRef(null);
    const deleteConfirm = useRef('');

    const deleteOld = async (e) =>{
        e.preventDefault();
        console.log(deleteConfirm.current);
        if (deleteConfirm.current === 'Delete') {
            const messageList = collection(db, "messages");
            const queryFeedback = query(
                messageList,
                where("board", "==", allUpdates[updateNumber].ID),
            );
            const deleteFeedback = async () => {
                let data = await getDocs(queryFeedback);
                data.forEach(async(message) => {
                    console.log(message);
                    console.log(`Deleting ${message.id}. Board owner: ${message.data().boardOwner}, deleter ${currentUser.uid}`);
                    await deleteDoc(doc(db, "messages", message.id));
                })
            };
            deleteFeedback()
            await deleteDoc(doc(db, "updates", allUpdates[updateNumber].ID));
            //This triggers a re-render
            setNewUpdateTracker(newUpdateTracker + 1)
            console.log(allUpdates[updateNumber].ID)
            deleteRef.current?.close();
        }
    }

    function closeDelete() {
        deleteRef.current?.close();
    } 

    function UpdateViewer() {
        if (allUpdates.length === 0) {
            return <button onClick={createNew}>
                Add your first update! <AddIcon width="34px"/>
            </button>
        } else {
            return <div>
                <div className='update-nav'>
                    {allUpdates.length>1 ? <button onClick={moveBackward}><BackIcon width='34px'/></button> : null }
                    <button onClick={() => deleteRef.current?.showModal()}><GarbageIcon width="34px"/></button>
                    <h2>Update:<br/>
                    {new Date(allUpdates[updateNumber].createdAt.seconds*1000).toLocaleDateString()}</h2>
                    <button onClick={createNew}><AddIcon width="34px"/></button>
                    {allUpdates.length>1 ? <button onClick={moveForward}><ForwardIcon width='34px'/></button> : null}
                </div>

                <Entry
                    boardOwner={currentUser.uid}
                    boardNumber={allUpdates[updateNumber].ID}
                    boardPicture={allUpdates[updateNumber].photoURL}
                    entryCollection="updates"
                    viewerType='writer'
                    newUpdateIncrementer={newUpdateIncrementer}
                />
                <dialog ref={deleteRef} className='deleteBox'>
                    <p>Type "Delete" below to continue:</p>
                    <input type="text" name="delete" id="delete" required="" onInput={e => deleteConfirm.current = e.target.value}/>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                        <button id="closeDialog" onClick={closeDelete} style={{}}>Exit</button>
                        <button id="confirmDialog" onClick={deleteOld} style={{}}>Delete</button>
                    </div>
                    
                </dialog>
            </div>
        };

    };

    return currentUser ? (
        <div className="journal">
            <h1>{userProfile.displayName}'s collection overview: </h1>
            {error && <div className="signup-alert-box">{error}</div>}
            <br />

            <Entry 
                boardOwner={currentUser.uid}
                boardNumber={currentUser.uid}
                boardPicture={boardPicture}
                entryCollection="users"
                viewerType='writer'
                newUpdateIncrementer={newUpdateIncrementer}
            />

            <UpdateViewer />

        </div>
    ) : <Navigate to="/signup" />
};
