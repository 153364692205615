import React, { useState, useRef, useEffect, useLayoutEffect }  from 'react';
import { addDoc, doc, getDoc, getDocs, collection, serverTimestamp, onSnapshot, query, where, orderBy, limit, startAfter, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext'
import SendIcon from "./icons/SendIcon";


export default function Feedback({boardOwner, className, board, journalHeight}){
    const [newMessage, setNewMessage] = useState("");
    const messagesRef = collection(db, "messages");
    const {currentUser} = useAuth();
    const displayName = useRef("");
    const [buttonVisible, setButtonVisible] = useState(true);
    const [addMessageVisible, setAddMessageVisible] = useState(true);

    const synonyms = ["thought", "said", "opined", "suggested", "commented",
        "hypothesized", "postulated", "deduced", "declared", "decided", "figured",
        "concluded", "exclaimed", "mentioned", "believed", "brainstormed", "responded",
        "corresponded", "bellowed", "roared", "shouted", "whispered", "replied", "blurted",
        "remarked", "vocalized", "" 
    ];

    const randomElement = Math.floor(Math.random() * synonyms.length);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newMessage === "") return;
        await addDoc(messagesRef, {
            text: newMessage,
            createdAt: serverTimestamp(),
            user: displayName.current, 
            userID: currentUser.uid,
            verb: synonyms[randomElement], 
            board, 
            boardOwner,
        });
        setNewMessage("");
    };
    
    useEffect(()=> {
        if (currentUser){
            const userProfileRef = doc(db, "users", currentUser.uid);
            const getUserProfile = async () => {
                try {
                    const data = await getDoc(userProfileRef);
                    displayName.current = (data.data().displayName)
                } catch(err){
                    console.error(err);
                }
            };
            getUserProfile();
            setAddMessageVisible(true);
        } else {setAddMessageVisible(false);};
    }, []);

    // This is setting the textarea height:
    const textareaRef = useRef(null);
    const [bumperHeight, setBumperHeight] = useState("150px");
    const [overflowHeight, setOverflowHeight] = useState(`${journalHeight - 55}px`);
    const MIN_TEXTAREA_HEIGHT = 40;
    useLayoutEffect(() => {
        if (currentUser){
            // Reset height - important to shrink on delete
            textareaRef.current.style.height = "inherit";
            // Set height
            textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
            )}px`;
            // This does the stupid bumper
            setBumperHeight(`${Math.max(
            textareaRef.current.scrollHeight +15,
            MIN_TEXTAREA_HEIGHT
            )}px`)
            // This is setting the message overflow container height:
            setOverflowHeight(`${journalHeight - 55 - textareaRef.current.scrollHeight}px`)
        } else {setOverflowHeight(`${journalHeight - 55}px`)};
      }, [newMessage, journalHeight]);


    // This is populating the list of messages: 
    const snapshotRef = useRef();
    const [oldMessages, setOldMessages] = useState([]);
    const [newMessages, setNewMessages] = useState([]);

    useEffect(() => {
        // First we grab the newest messages, using a snapshot so new messages are automatically added to the bottom.
        const queryMessages = query(
            messagesRef, 
            where("board", "==", board),
            orderBy("createdAt", "desc"),
            limit(1)
         );

        const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                let messages = newMessages;
                if (change.type === "added") {                  
                    snapshot.forEach((doc) => {
                        messages.unshift({...doc.data(), id: doc.id });                       
                    });
                    setNewMessages(messages);
                }
            });          
        });

        // this is here to "clean up" the use effect.
        return () => unsubscribe();
    }, []);

    useEffect(()=>{
        let messages = [...oldMessages];
        if (messages.length === 0) {
            // Then we grab the first batch of older messages.
            const firstMessages = query(
                messagesRef, 
                where("board", "==", board),
                orderBy("createdAt", "desc"),
                limit(6)
            );
            
            const fetchData = async () => {
                let data = await getDocs(firstMessages);
                let dataCount = await getCountFromServer(firstMessages);
                if (dataCount.data().count < 6){
                    setButtonVisible(false)
                }
                data.forEach((doc) => {
                    messages.push({...doc.data(), id: doc.id});
                });
                setOldMessages(messages.slice(1));
            };

            fetchData();
        };
    },[])

    
    // Then we grab older messages when/if the user requests them.
    async function loadMore () {
        snapshotRef.current = oldMessages[oldMessages.length-1].createdAt;
        const nextMessages = query(
            messagesRef, 
            where("board", "==", board),
            orderBy("createdAt", "desc"),
            startAfter(snapshotRef.current),
            limit(6)
        );

        let messages = [...oldMessages];
        const moreData = await getDocs(nextMessages);
        if (moreData.length < 6){
            setButtonVisible(false)
        }
        moreData.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            messages.push({...doc.data(), id: doc.id});
          });
        setOldMessages(messages);
    };
    
    function Messages() {
        return <div className="message-board">
            {newMessages.map((message) => 
                <div className={message.userID===boardOwner ? "card owner" : "card guest"}>
                    <h4>{message.user} {message.verb}:</h4>
                    <p>{message.text}</p>
                </div>           
            )}
            {oldMessages.map((message) => 
                <div className={message.userID===boardOwner ? "card owner" : "card guest"}>
                    <h4>{message.user} {message.verb}:</h4>
                    <p>{message.text}</p>
                </div>
            )}
        </div>
    };

    return (
        <div className={className}>
            <h2>Feedback</h2>
            <div className='overflow-container'
                style={{
                    minHeight: "100px",
                    maxHeight: overflowHeight
                }}>
                {buttonVisible && <button onClick={loadMore}>Load More</button>}
                <Messages />
            </div>

            {addMessageVisible && <div 
                className="bottom-bumper" 
                style={{
                    minHeight: "45px",
                    height: bumperHeight
                }}
            ></div>}


            {addMessageVisible && <div className='new-message-space'>
                <form onSubmit={handleSubmit} className="new-message-form">
                    <textarea
                        className="new-massage-input"
                        placeholder="Add a comment here..."
                        onChange={(e => setNewMessage(e.target.value))}
                        value={newMessage}
                        ref={textareaRef}
                        style={{
                          minHeight: MIN_TEXTAREA_HEIGHT,
                          resize: "none"
                        }}
                    />
                    <button type="submit" className="send-button">
                        <SendIcon width="24px"/>
                    </button>
                </form>
            </div>}
        </div>
    )
}
