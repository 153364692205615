import { v4 } from "uuid";
import { storage } from "../../firebase";
import { useRef, useState } from "react";
import setCanvasPreview from "./setCanvasPreview";
import {useAuth} from '../../contexts/AuthContext';
import {ref, uploadBytes, getDownloadURL} from "firebase/storage";
import ReactCrop, {centerCrop, convertToPixelCrop, makeAspectCrop} from "react-image-crop";
import { doc, setDoc } from "firebase/firestore";
import { db } from '../../firebase'


const ImageCropper = ({ closeModal, updateAvatar, modalType, boardNumber, newUpdateIncrementer }) => {
  let ASPECT_RATIO = 1.3;
  let MIN_DIMENSION = 600;
  let MAX_DIMENSION = 2400;
  let FILE_NAME = 'profile'
  if (modalType === 'primary'){
    FILE_NAME = 'primary';
  };
  if (modalType === 'profile'){
    ASPECT_RATIO = 1;
    MIN_DIMENSION = 96;
    MAX_DIMENSION = 1200;
  };
  if (modalType === 'update'){
    FILE_NAME = boardNumber
  }


  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [error, setError] = useState("");
  const {currentUser} = useAuth()

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError(`Image must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels.`);
          return setImgSrc("");
        }
        if (naturalWidth > MAX_DIMENSION || naturalHeight > MAX_DIMENSION) {
          setError(`Image must be at most ${MAX_DIMENSION} x ${MAX_DIMENSION} pixels.`);
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        className="image_cropper_select"
      />
      {error && <p className="image_cropper_error">{error}</p>}
      {imgSrc && (
        <div className="image_cropper_react_crop">
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            circularCrop={modalType === 'profile' ? true : false}
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
            minHeight={MIN_DIMENSION}
            maxHeight={MAX_DIMENSION}
            maxWidth={MAX_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "70vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <button
            className="image_cropper_button"
            onClick={() => {
              setCanvasPreview(
                imgRef.current, // HTMLImageElement
                previewCanvasRef.current, // HTMLCanvasElement
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              
              const dataUrl = previewCanvasRef.current.toDataURL();

              previewCanvasRef.current.toBlob((blob) => {
                // The line below is saved as a comment to remember how v4 works.
                // const storageRef = ref(storage, `images/${v4()}`);
                
                const storageRef = ref(storage, `${currentUser.uid}/${FILE_NAME}`);
                uploadBytes(storageRef, blob).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then((url) => {
                    if (modalType === 'primary'){
                      setDoc(doc(db, "users", currentUser.uid), 
                      {primaryURL: url}, 
                      {merge:true});
                    } else if (modalType === 'profile') {
                      setDoc(doc(db, "users", currentUser.uid), 
                      {profileURL: url}, 
                      {merge:true});
                    } else { //The default modalType is 'update'.
                      setDoc(doc(db, "updates", boardNumber), 
                      {photoURL: url}, 
                      {merge:true});
                      newUpdateIncrementer(url={url});
                    }
                  });
                });
              },  
              "image/jpeg",
              0.95,);

              updateAvatar(dataUrl);
              closeModal();
            }}
          >
            Crop Image
          </button>
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="image_cropper_canvas"
        />
      )}
    </>
  );
};
export default ImageCropper;